import {
  createCustomerMutation,
  updateCustomerMutation,
} from '@data/shopify/admin/mutations/customers'
import {
  type CustomerInput,
  type UpdateCustomerMutation,
  type UpdateCustomerMutationVariables,
} from '@data/shopify/admin/types'
import {
  getCustomerAddressesQuery,
  getCustomerOrdersQuery,
} from '@data/shopify/storefront/queries/customers'
import {
  createCustomerAccessTokenMutation,
  deleteCustomerAccessTokenMutation,
  recoverCustomerMutation,
} from '@data/shopify/storefront/mutations/customers'
import {
  type CreateCustomerAccessTokenMutation,
  type CreateCustomerAccessTokenMutationVariables,
  type DeleteCustomerAccessTokenMutation,
  type DeleteCustomerAccessTokenMutationVariables,
  type CustomerCreateInput,
  type CreateCustomerMutation,
  type CreateCustomerMutationVariables,
  type RecoverCustomerMutation,
  type RecoverCustomerMutationVariables,
  type GetCustomerOrdersQuery,
  type GetCustomerOrdersQueryVariables,
  type GetCustomerAddressesQuery,
  type GetCustomerAddressesQueryVariables,
} from '@data/shopify/storefront/types'
import { ParseError } from '@lib/request'
import { type ShopifyClient, parseUserErrors } from './client'

/**
 * Gets customer with addresses from Shopify.
 */
export const getShopifyCustomerWithAddresses = async (
  shopifyStorefrontClient: ShopifyClient,
  token: string
) => {
  const getCustomerAddressesResult = await shopifyStorefrontClient.query<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >({
    query: getCustomerAddressesQuery,
    variables: {
      token,
    },
  })

  const customer = getCustomerAddressesResult.data?.customer ?? undefined

  return customer
}

/**
 * Gets customer with orders from Shopify.
 */
export const getShopifyCustomerWithOrders = async (
  shopifyStorefrontClient: ShopifyClient,
  token: string,
  after: string | null,
  limit = 10
) => {
  const getCustomerOrdersResult = await shopifyStorefrontClient.query<
    GetCustomerOrdersQuery,
    GetCustomerOrdersQueryVariables
  >({
    query: getCustomerOrdersQuery,
    variables: {
      token,
      limit,
      after,
    },
  })

  const customer = getCustomerOrdersResult.data.customer ?? undefined

  return customer
}

/**
 * Creates a new Shopify customer.
 */
export const createShopifyCustomer = async (
  shopifyAdminClient: ShopifyClient,
  input: CustomerCreateInput
) => {
  const createCustomerResult = await shopifyAdminClient.mutate<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >({
    mutation: createCustomerMutation,
    variables: {
      input,
    },
  })

  const createCustomerParseResults = parseUserErrors(
    createCustomerResult.data?.customerCreate?.customerUserErrors
  )

  if (createCustomerParseResults.errorCount > 0) {
    throw new ParseError(createCustomerParseResults)
  }

  const customer =
    createCustomerResult.data?.customerCreate?.customer ?? undefined

  return customer
}

/**
 * Updates a new Shopify customer.
 */
export const updateShopifyCustomer = async (
  shopifyAdminClient: ShopifyClient,
  input: CustomerInput
) => {
  const updateCustomerResult = await shopifyAdminClient.mutate<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >({
    mutation: updateCustomerMutation,
    variables: {
      input,
    },
  })

  const updateCustomerParseResults = parseUserErrors(
    updateCustomerResult.data?.customerUpdate?.userErrors
  )

  if (updateCustomerParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerParseResults)
  }

  const customer =
    updateCustomerResult.data?.customerUpdate?.customer ?? undefined

  return customer
}

/**
 * Creates a new user access token from credentials.
 */
export const createShopifyCustomerAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string,
  password: string
) => {
  const createCustomerAccessTokenResult = await shopifyStorefrontClient.mutate<
    CreateCustomerAccessTokenMutation,
    CreateCustomerAccessTokenMutationVariables
  >({
    mutation: createCustomerAccessTokenMutation,
    variables: {
      input: {
        email,
        password,
      },
    },
  })

  const updateCustomerParseResults = parseUserErrors(
    createCustomerAccessTokenResult.data?.customerAccessTokenCreate
      ?.customerUserErrors
  )

  if (updateCustomerParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerParseResults)
  }

  const customerAccessToken =
    createCustomerAccessTokenResult.data?.customerAccessTokenCreate
      ?.customerAccessToken ?? undefined

  return customerAccessToken
}

/**
 * Deletes Shopify customer access token.
 */
export const deleteShopifyCustomerAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  token: string
) => {
  await shopifyStorefrontClient.mutate<
    DeleteCustomerAccessTokenMutation,
    DeleteCustomerAccessTokenMutationVariables
  >({
    mutation: deleteCustomerAccessTokenMutation,
    variables: {
      token,
    },
  })
}

/**
 * Sends password recovery email to Shopify customer.
 */
export const recoverShopifyCustomerPassword = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string
) => {
  const recoverCustomerResult = await shopifyStorefrontClient.mutate<
    RecoverCustomerMutation,
    RecoverCustomerMutationVariables
  >({
    mutation: recoverCustomerMutation,
    variables: {
      email,
    },
  })

  const recoverCustomerParseResults = parseUserErrors(
    recoverCustomerResult.data?.customerRecover?.customerUserErrors
  )

  if (recoverCustomerParseResults.errorCount > 0) {
    throw new ParseError(recoverCustomerParseResults)
  }
}
