import { type SanityClient } from '@sanity/client'

import {
  type AttributeInput,
  type CartFragmentFragment,
} from '@data/shopify/storefront/types'
import { shopifyFrameBuilderProductType } from '@frame-builder/lib/product'
import {
  type AutomaticDiscount,
  type Cart,
  type LineItem,
} from '@lib/cart/types'
import { type Locale } from '@lib/language'
import { shopifyCombinedListingProductType } from '@lib/product/combined-listing'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product-variant'
import { shopifyCombinedListingIdAttribute } from './attribute'
import { parseShopifyGlobalId } from './client'

type CartFragmentFragmentLine =
  CartFragmentFragment['lines']['edges'][0]['node']

/**
 * Parses Shopify line items.
 */
const parseShopifyCartLineItems = async (
  locale: Locale,
  sanityClient: SanityClient,
  lineItems: CartFragmentFragmentLine[]
) => {
  // Get product variant data from Sanity
  const variantIds = lineItems
    .map((lineItem) =>
      lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null
    )
    .filter(Boolean) as number[]
  const productVariants = await getProductVariants(
    sanityClient,
    locale,
    variantIds
  )

  return lineItems
    .map((lineItem) => {
      const variantId = lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null

      // Parse Shopify data for products which don't have any data in Sanity
      if (
        [
          shopifyFrameBuilderProductType,
          shopifyCombinedListingProductType,
        ].includes(lineItem.merchandise.product.productType)
      ) {
        const combinedListingIdAttribute = lineItem.attributes.find(
          (attribute) => attribute.key === shopifyCombinedListingIdAttribute
        )
        const combinedListingId = combinedListingIdAttribute?.value

        const productId = lineItem.merchandise.product.id
          ? parseShopifyGlobalId(`${lineItem.merchandise.product.id}`)
          : null
        const imageUrl = lineItem.merchandise.image?.url as string | undefined

        if (!productId || !variantId) {
          return
        }

        const customVariantLineItem: LineItem = {
          lineId: lineItem.id,
          quantity: lineItem.quantity,
          variantID: variantId,
          title: lineItem.merchandise.title,
          imageUrl,
          attributes: lineItem.attributes.map((attribute) => ({
            key: attribute.key,
            value: attribute.value ?? '',
          })),
          options: lineItem.merchandise.selectedOptions.map(
            (selectedOption, index) => ({
              position: index + 1,
              name: selectedOption.name,
              value: selectedOption.value,
            })
          ),
          // Convert from major units to minor units
          price: parseFloat(lineItem.merchandise.price.amount) * 100,
          product: {
            productID: productId,
            title: lineItem.merchandise.product.title,
            combinedListingProductType: 'none',
            // Used to calculate user product and product category discounts
            _id: combinedListingId ?? '',
            productCategoryId: '',
            collectionIds: [],
            // Used to link cart item to a product page
            slug: {
              current: '',
            },
          },
        }
        return customVariantLineItem
      }

      const variant = variantId
        ? productVariants.find(
            (productVariant) => productVariant.variantID === variantId
          )
        : null

      if (!variant) {
        return
      }

      const variantLineItem: LineItem = {
        lineId: lineItem.id,
        quantity: lineItem.quantity,
        attributes: lineItem.attributes
          .filter((attribute) => !!attribute.value)
          .map((attribute) => ({
            key: attribute.key,
            value: attribute.value,
          })) as AttributeInput[],
        ...variant,
      }
      return variantLineItem
    })
    .filter(Boolean) as LineItem[]
}

/**
 * Parses Shopify cart.
 */
export const parseShopifyCart = async (
  locale: Locale,
  shopifyCart?: CartFragmentFragment
) => {
  if (!shopifyCart) {
    return
  }

  const sanityClient = getSanityClient()

  // Lien items
  const lineItems = shopifyCart.lines.edges.map((edge) => edge.node)
  const parsedLineItems = await parseShopifyCartLineItems(
    locale,
    sanityClient,
    lineItems
  )

  // Discounts
  const automaticDiscount = lineItems.reduce<AutomaticDiscount>(
    (previousAutomaticDiscount, lineItem) =>
      lineItem.discountAllocations.reduce<AutomaticDiscount>(
        (subPreviousAutomaticDiscount, discountAllocation) => {
          if (
            discountAllocation.__typename !== 'CartAutomaticDiscountAllocation'
          ) {
            return subPreviousAutomaticDiscount
          }

          return {
            title: discountAllocation.title,
            amount:
              (subPreviousAutomaticDiscount.amount ?? 0) +
              Number(discountAllocation.discountedAmount.amount) * 100,
          }
        },
        previousAutomaticDiscount
      ),
    {}
  )
  const discountCodes = shopifyCart.discountCodes.map(
    (discountCode) => discountCode.code
  )

  // Shipping
  const shippingOption =
    shopifyCart?.deliveryGroups?.edges?.[0]?.node?.selectedDeliveryOption
  const hasShippingOptions = !!shippingOption
  const shippingTitle = shippingOption?.title ?? undefined

  // Prices
  const subtotal = shopifyCart.cost.subtotalAmount
    ? Number(shopifyCart.cost.subtotalAmount.amount) * 100
    : 0
  const shipping = shippingOption?.estimatedCost?.amount
    ? Number(shippingOption.estimatedCost.amount) * 100
    : undefined

  const cart: Cart = {
    id: shopifyCart.id,
    lineItems: parsedLineItems,
    prices: {
      subtotal,
      shipping,
      automaticDiscount: automaticDiscount.amount,
    },
    webUrl: `${shopifyCart.checkoutUrl}`,
    customerId: shopifyCart.buyerIdentity.customer?.id,
    hasShippingOptions,
    shippingTitle,
    automaticDiscount,
    discountCodes,
  }
  return cart
}
