import {
  addCartLinesMutation,
  createCartMutation,
  removeCartLinesMutation,
  updateCartAttributesMutation,
  updateCartBuyerIdentityMutation,
  updateCartDiscountCodesMutation,
  updateCartLinesMutation,
  updateCartNoteMutation,
} from '@data/shopify/storefront/mutations/cart'
import { getCartQuery } from '@data/shopify/storefront/queries/cart'
import {
  type AddCartLinesMutation,
  type AddCartLinesMutationVariables,
  type AttributeInput,
  type CartBuyerIdentityInput,
  type CartFragmentFragment,
  type CartInput,
  type CartLineInput,
  type CartLineUpdateInput,
  type CreateCartMutation,
  type CreateCartMutationVariables,
  type GetCartQuery,
  type GetCartQueryVariables,
  type RemoveCartLinesMutation,
  type RemoveCartLinesMutationVariables,
  type UpdateCartAttributesMutation,
  type UpdateCartAttributesMutationVariables,
  type UpdateCartBuyerIdentityMutation,
  type UpdateCartBuyerIdentityMutationVariables,
  type UpdateCartDiscountCodesMutation,
  type UpdateCartDiscountCodesMutationVariables,
  type UpdateCartLinesMutation,
  type UpdateCartLinesMutationVariables,
  type UpdateCartNoteMutation,
  type UpdateCartNoteMutationVariables,
} from '@data/shopify/storefront/types'
import { type ShopifyClient, getUserErrorMessage } from './client'

/**
 * Gets a Shopify cart.
 */
export const getShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string
) => {
  const getCartResult = await shopifyStorefrontClient.query<
    GetCartQuery,
    GetCartQueryVariables
  >({
    query: getCartQuery,
    variables: {
      cartId,
    },
  })

  const cart = getCartResult.data?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartInput: CartInput
) => {
  const createCartResult = await shopifyStorefrontClient.mutate<
    CreateCartMutation,
    CreateCartMutationVariables
  >({
    mutation: createCartMutation,
    variables: {
      cartInput,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    createCartResult.data?.cartCreate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = createCartResult.data?.cartCreate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates buyer indentity in Shopify cart.
 */
export const updateShopifyCartBuyerIdentity = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  buyerIdentity: CartBuyerIdentityInput
) => {
  const updateCartBuyerIdentityResult = await shopifyStorefrontClient.mutate<
    UpdateCartBuyerIdentityMutation,
    UpdateCartBuyerIdentityMutationVariables
  >({
    mutation: updateCartBuyerIdentityMutation,
    variables: {
      cartId,
      buyerIdentity,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartBuyerIdentityResult.data?.cartBuyerIdentityUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartBuyerIdentityResult.data?.cartBuyerIdentityUpdate?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates discount codes in Shopify cart.
 */
export const updateShopifyCartDiscountCodes = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  discountCodes: string[]
) => {
  const updateCartDiscountCodesResult = await shopifyStorefrontClient.mutate<
    UpdateCartDiscountCodesMutation,
    UpdateCartDiscountCodesMutationVariables
  >({
    mutation: updateCartDiscountCodesMutation,
    variables: {
      cartId,
      discountCodes,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartDiscountCodesResult.data?.cartDiscountCodesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartDiscountCodesResult.data?.cartDiscountCodesUpdate?.cart ??
    undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates attributes for Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[]
) => {
  const updateCartAttributesResult = await shopifyStorefrontClient.mutate<
    UpdateCartAttributesMutation,
    UpdateCartAttributesMutationVariables
  >({
    mutation: updateCartAttributesMutation,
    variables: {
      cartId,
      attributes,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartAttributesResult.data?.cartAttributesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart =
    updateCartAttributesResult.data?.cartAttributesUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates note for Shopify cart.
 */
export const updateShopifyCartNote = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  note: string
) => {
  const updateCartNoteResult = await shopifyStorefrontClient.mutate<
    UpdateCartNoteMutation,
    UpdateCartNoteMutationVariables
  >({
    mutation: updateCartNoteMutation,
    variables: {
      cartId,
      note,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartNoteResult.data?.cartNoteUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = updateCartNoteResult.data?.cartNoteUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Adds new line items to Shopify cart.
 */
export const addLineItemsToShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[]
) => {
  const addCartLinesResult = await shopifyStorefrontClient.mutate<
    AddCartLinesMutation,
    AddCartLinesMutationVariables
  >({
    mutation: addCartLinesMutation,
    variables: {
      cartId,
      lines,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    addCartLinesResult.data?.cartLinesAdd?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = addCartLinesResult.data?.cartLinesAdd?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Updates line items in Shopify cart.
 */
export const updateLineItemsInShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[]
) => {
  const updateCartLinesResult = await shopifyStorefrontClient.mutate<
    UpdateCartLinesMutation,
    UpdateCartLinesMutationVariables
  >({
    mutation: updateCartLinesMutation,
    variables: {
      cartId,
      lines,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    updateCartLinesResult.data?.cartLinesUpdate?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = updateCartLinesResult.data?.cartLinesUpdate?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}

/**
 * Removes line items from Shopify cart.
 */
export const removeLineItemsFromShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lineIds: string[]
) => {
  const removeCartLinesResult = await shopifyStorefrontClient.mutate<
    RemoveCartLinesMutation,
    RemoveCartLinesMutationVariables
  >({
    mutation: removeCartLinesMutation,
    variables: {
      cartId,
      lineIds,
    },
  })

  const userErrorMessage = getUserErrorMessage(
    removeCartLinesResult.data?.cartLinesRemove?.userErrors
  )

  if (userErrorMessage) {
    throw new Error(userErrorMessage)
  }

  const cart = removeCartLinesResult.data?.cartLinesRemove?.cart ?? undefined

  return cart as CartFragmentFragment | undefined
}
