import { getLocaleVariable } from '@lib/environment'
import { type Locale } from '@lib/language'
import { type Parameter } from '@lib/parameters'
import { parseRequiredParameter } from '@lib/request'

type ShopifyResourceType =
  | 'Customer'
  | 'MailingAddress'
  | 'MediaImage'
  | 'Product'
  | 'ProductImage'
  | 'ProductVariant'

const shopifyApiVersion = '2024-10'

/**
 * Gets Shopify domain.
 */
export const getShopifyDomain = (locales: Locale[] | Locale) => {
  const locale = parseRequiredParameter<Locale>(locales)
  const storeId = getLocaleVariable(locale, 'NEXT_PUBLIC_SHOPIFY_STORE_ID')

  if (!storeId) {
    return
  }

  return `${storeId}.myshopify.com`
}

/**
 * Gets Shopify Storefront API URL.
 */
export const getShopifyStorefrontApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/api/${shopifyApiVersion}`

/**
 * Gets Shopify Admin API URL.
 */
export const getShopifyAdminApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/admin/api/${shopifyApiVersion}`

/**
 * Gets a Shopify global ID.
 */
export const getShopifyGlobalId = (
  resourceType: ShopifyResourceType,
  id: string | number,
  parameters?: Parameter[]
) => {
  let parameterString =
    parameters
      ?.map((parameter) => `${parameter.name}=${parameter.value}`)
      ?.join('&') ?? ''
  parameterString = parameterString.length > 0 ? `?${parameterString}` : ''

  return `gid://shopify/${resourceType}/${id}${parameterString}`
}

/**
 * Gets Shopify ID from Shopify global ID.
 */
export const parseShopifyGlobalId = (shopifyGlobalId: string) => {
  try {
    return Number(shopifyGlobalId.split('?')[0].split('/').slice(-1)[0])
  } catch {
    return
  }
}
