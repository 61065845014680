import axios, { type AxiosResponse } from 'axios'
import {
  type ContentName,
  type ContentType,
  type ID,
  type ItemPrice,
  type Quantity,
  type Title,
  type CustomProperties,
  type Value,
  type Currency,
  type NumItems,
  type Email,
  type FirstName,
  type LastName,
  CustomData,
} from 'facebook-nodejs-business-sdk'
import { useEffect } from 'react'

import {
  type SanityProductVariantOption,
  type SanityProductVariantWithProduct,
} from '@data/sanity/queries/types/product'
import { type Locale } from '@lib/language'
import { type Cart } from './cart/types'
import { type DiscountItem } from './discount/types'

export enum FacebookEvent {
  VIEW_CONTENT = 'ViewContent',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  ADD_TO_CART = 'AddToCart',
  INITIATE_CHECKOUT = 'InitiateCheckout',
}

export interface FacebookEventContent {
  id?: ID
  itemPrice?: ItemPrice
  quantity?: Quantity
  title?: Title
}

export interface FacebookEventCustomData {
  contentName?: ContentName
  contentType?: ContentType
  customProperties?: CustomProperties
  contentIds?: ID[]
  contents?: FacebookEventContent[]
  value?: Value
  currency?: Currency
  numItems?: NumItems
}

export interface FacebookEventUserData {
  email?: Email
  firstName?: FirstName
  lastName?: LastName
}

export type FacebookEventData = FacebookEventCustomData & FacebookEventUserData

/**
 * Triggers a Facebook event.
 */
export const triggerFacebookEvent = async (
  eventName: FacebookEvent,
  locale: Locale,
  data?: FacebookEventData
) => {
  try {
    const payload = JSON.stringify({
      eventName,
      ...data,
    })

    await axios.post<CustomData, AxiosResponse<CustomData>, string>(
      '/api/facebook',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )
  } catch (error) {
    console.error(error)
  }
}

/**
 * Triggers a view content Facebook event.
 */
export const triggerViewContentFacebookEvent = async (
  locale: Locale,
  productTitle: string,
  productVariantId?: number,
  productVariantOptions?: SanityProductVariantOption[]
) =>
  triggerFacebookEvent(FacebookEvent.VIEW_CONTENT, locale, {
    contentType: 'product',
    contentName: productTitle,
    contentIds: [productVariantId?.toString() ?? ''],
    customProperties: {
      options: productVariantOptions?.map((option) => option.value),
    },
  })

/**
 * Triggers an initiate checkout Facebook event.
 */
export const triggerInitiateCheckoutFacebookEvent = async (
  locale: Locale,
  cart: Cart,
  cartDiscountItems: DiscountItem[],
  currencyCode?: string
) => {
  const contentIds = cart.lineItems.map((lineItem) =>
    lineItem.variantID.toString()
  )
  const contents: FacebookEventContent[] = cart.lineItems.map((lineItem) => {
    const price = lineItem.price ?? 0
    return {
      id: lineItem.variantID.toString(),
      itemPrice: price / 100,
      quantity: lineItem.quantity,
      title: lineItem.product.title,
    }
  })

  const discountTitle = cartDiscountItems
    .map((cartDiscountItem) => cartDiscountItem.title)
    .join(', ')
  const discountAmount =
    cartDiscountItems.reduce((total, cartDiscountItem) => {
      const quantity = cartDiscountItem.quantity ?? 1
      return total + cartDiscountItem.amount * quantity
    }, 0) / 100

  const customProperties: CustomProperties = {
    discount: {
      title: discountTitle,
      amount: discountAmount,
    },
    automaticDiscount: {
      title: '',
      amount: 0,
    },
  }

  if (typeof cart.automaticDiscount?.amount !== 'undefined') {
    customProperties.automaticDiscount = {
      title: cart.automaticDiscount.title ?? '',
      amount: cart.automaticDiscount.amount / 100,
    }
  }

  await triggerFacebookEvent(FacebookEvent.INITIATE_CHECKOUT, locale, {
    contentIds,
    contents,
    customProperties,
    value: cart.prices.subtotal / 100,
    currency: currencyCode,
    numItems: cart.lineItems.length,
  })
}

/**
 * Triggers an add to cart Facebook event.
 */
export const triggerAddToCartFacebookEvent = async (
  locale: Locale,
  variant: SanityProductVariantWithProduct
) =>
  triggerFacebookEvent(FacebookEvent.ADD_TO_CART, locale, {
    contentType: 'product',
    contentName: variant.product.title,
    contentIds: [variant.variantID.toString()],
    customProperties: {
      options: variant.options.map((option) => option.value),
    },
  })

/**
 * View content Facebook event hook.
 */
export const useViewContentFacebookEvent = (
  locale: Locale,
  productTitle?: string,
  productVariantId?: number,
  productVariantOptions?: SanityProductVariantOption[],
  facebookEvents?: boolean
) => {
  useEffect(() => {
    if (!facebookEvents || !productTitle) {
      return
    }

    triggerViewContentFacebookEvent(
      locale,
      productTitle,
      productVariantId,
      productVariantOptions
    )
  }, [
    locale,
    facebookEvents,
    productTitle,
    productVariantId,
    productVariantOptions,
  ])
}
