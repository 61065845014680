import { type AnchorHTMLAttributes } from 'react'
import cx from 'classnames'

import { ButtonProps, getButtonStyles, ButtonIcon, ButtonLabel } from './button'

export {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  ButtonIconAlignment,
} from './button'

const ButtonLink = ({
  id,
  children,
  className,
  href,
  target,
  rel,
  tabIndex,
  style,
  onClick,
  variant,
  size,
  color,
  icon,
  iconAlignment,
  isActive,
  hideLabelUntil,
}: ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a
    id={id}
    href={href}
    className={cx(
      'group',
      getButtonStyles({
        variant,
        size,
        color,
        isActive,
        icon,
        hideLabelUntil,
        children,
      }),
      className
    )}
    target={target}
    tabIndex={tabIndex}
    rel={rel}
    style={style}
    onClick={onClick}
  >
    {children && (
      <ButtonLabel hideUntil={hideLabelUntil}>{children}</ButtonLabel>
    )}
    {icon && <ButtonIcon name={icon} alignment={iconAlignment} size={size} />}
  </a>
)

export default ButtonLink
