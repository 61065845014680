import { useCallback, useContext } from 'react'

import {
  type CartLineInput,
  type CartLineUpdateInput,
} from '@data/shopify/storefront/types'
import { triggerAddToCartFacebookEvent } from '@lib/facebook-client'
import { LanguageContext } from '@lib/language-context'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product-variant'
import { ShopContext } from '@lib/shop-context'
import { getTaxCartAttributes } from '@lib/shopify/attribute'
import { getShopifyGlobalId } from '@lib/shopify/client'
import {
  addLineItemsToShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
  updateShopifyCartAttrbites,
  updateShopifyCartNote,
} from '@lib/shopify/graphql/cart'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import { CartContext } from './context'
import { validateCart } from './helpers'
import { updateCartDiscount } from './request'
import { type CartFormValues, type CartVariantLineItem } from './types'

/**
 * Returns a hook that adds an item to cart.
 */
export const useAddItemsToCart = () => {
  const {
    cart,
    toggleCart,
    saveCart,
    setIsCartUpdating,
    setCartAddingProductIds,
  } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { settings } = useContext(SiteContext)

  return useCallback(
    async (variantLineItems: CartVariantLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setCartAddingProductIds(variantLineItems.map((item) => item.id))
        setIsCartUpdating(true)

        // Get variant details from Sanity
        const sanityClient = getSanityClient()
        const variantIds = variantLineItems.map(
          (variantLineItem) => variantLineItem.id
        )
        const productVariants = await getProductVariants(
          sanityClient,
          locale,
          variantIds
        )

        // Add line items to cart
        const lines = variantLineItems.map((variantLineItem) => {
          const cartLineInput: CartLineInput = {
            merchandiseId: getShopifyGlobalId(
              'ProductVariant',
              variantLineItem.id
            ),
            quantity: variantLineItem.quantity,
            attributes: variantLineItem.attributes ?? [],
          }
          return cartLineInput
        })
        await addLineItemsToShopifyCart(shopifyStorefrontClient, cart.id, lines)

        if (settings?.facebookEvents) {
          productVariants.forEach(async (variant) => {
            await triggerAddToCartFacebookEvent(locale, variant)
          })
        }

        // Update cart discount codes
        const newCart = await updateCartDiscount(locale, cart.id)

        saveCart(locale, newCart)

        setCartAddingProductIds([])
        setIsCartUpdating(false)
        toggleCart(false)

        return true
      } catch (error) {
        console.log(error)

        setCartAddingProductIds([])
        setIsCartUpdating(false)
        return false
      }
    },
    [
      cart.id,
      locale,
      saveCart,
      setCartAddingProductIds,
      setIsCartUpdating,
      shopifyStorefrontClient,
      settings,
      toggleCart,
    ]
  )
}

/**
 * Returns a hook that updates an item in cart.
 */
export const useUpdateCartItem = () => {
  const { cart, saveCart, setIsCartUpdating } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[], quantity: number): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setIsCartUpdating(true)

        // Update cart line items
        const lines = ids.map((id) => {
          const cartLineUpdateInput: CartLineUpdateInput = {
            id,
            quantity,
          }
          return cartLineUpdateInput
        })
        await updateLineItemsInShopifyCart(
          shopifyStorefrontClient,
          cart.id,
          lines
        )

        // Update cart discount codes
        const newCart = await updateCartDiscount(locale, cart.id)

        saveCart(locale, newCart)

        setIsCartUpdating(false)

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient]
  )
}

/**
 * Returns a hook that removes an item from cart.
 */
export const useRemoveItemFromCart = () => {
  const { cart, saveCart, setIsCartUpdating } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      try {
        if (!shopifyStorefrontClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        // Compare current cart ID with saved cart ID
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          const cartId = localStorage.getItem(shopifyCartIdStorageKey)

          if (cart.id !== cartId) {
            return false
          }
        }

        setIsCartUpdating(true)

        // Remove line item from Shopify cart
        await removeLineItemsFromShopifyCart(
          shopifyStorefrontClient,
          cart.id,
          ids
        )

        // Update cart discount codes
        const newCart = await updateCartDiscount(locale, cart.id)

        saveCart(locale, newCart)

        setIsCartUpdating(false)

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient]
  )
}

/**
 * Returns a hook that prepares cart for order process.
 */
export const usePrepareCart = () => {
  const { cart } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { countryCode, shopifyStorefrontClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return useCallback(
    async (values: CartFormValues) => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      // Compare current cart ID with saved cart ID
      if (typeof window !== 'undefined') {
        const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
        const cartId = localStorage.getItem(shopifyCartIdStorageKey)

        if (cart.id !== cartId) {
          return {
            errors: {
              cart: 'Cart ID mismatch',
            },
          }
        }
      }

      // Validate cart form
      const validateCartResult = await validateCart(strings, values)

      if (cart?.id && Object.entries(validateCartResult.errors).length === 0) {
        // Update cart attributes
        const taxCartAttributes = getTaxCartAttributes(
          values,
          validateCartResult.vatIdCountryCode !== countryCode
        )

        try {
          await updateShopifyCartAttrbites(shopifyStorefrontClient, cart.id, [
            ...taxCartAttributes,
          ])

          // Update cart note
          await updateShopifyCartNote(
            shopifyStorefrontClient,
            cart.id,
            values.comment ?? ''
          )
        } catch (error) {
          console.log(error)
        }
      }

      return {
        errors: validateCartResult.errors,
      }
    },
    [cart.id, countryCode, locale, shopifyStorefrontClient, strings]
  )
}
