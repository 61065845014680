import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface ProductPriceProps {
  price?: number
  comparePrice?: number
  variant?: 'sm' | 'lg'
  showOldPrice?: boolean
  showDiscountPercent?: boolean
  className?: string
}

const ProductPrice = ({
  price,
  comparePrice,
  variant,
  showOldPrice,
  showDiscountPercent,
  className,
}: ProductPriceProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const comparePriceDiscountPercent = useMemo(() => {
    if (!price || !comparePrice || price < comparePrice) {
      return
    }

    const discountPercent = ((price - comparePrice) / price) * 100

    return discountPercent > 0 && discountPercent < 1
      ? Math.ceil(discountPercent)
      : Math.round(discountPercent)
  }, [comparePrice, price])

  const priceString = useMemo(() => {
    if (comparePrice) {
      return getPriceWithCurrency(comparePrice, currency, {
        taxRate,
        thousandSeparator: ',',
      })
    }

    if (price) {
      return getPriceWithCurrency(price, currency, {
        taxRate,
        thousandSeparator: ',',
      })
    }

    return '-'
  }, [comparePrice, currency, price, taxRate])

  const oldPriceString = useMemo(() => {
    if (price && comparePrice) {
      return getPriceWithCurrency(price, currency, {
        taxRate,
        thousandSeparator: ',',
      })
    }
  }, [comparePrice, currency, price, taxRate])

  return (
    <div
      className={cx(
        'inline-flex shrink-0 flex-wrap items-center gap-x-3 gap-y-1 leading-normal',
        {
          'font-medium': variant !== 'sm',
          'text-sm': variant === 'sm',
          'text-lg': variant === 'lg',
        },
        className
      )}
    >
      <span>{priceString}</span>

      {!!oldPriceString && showOldPrice && (
        <span
          className={cx('line-through', {
            'text-sm': !variant,
            'text-xs': variant === 'sm',
            'text-base': variant === 'lg',
          })}
        >
          {oldPriceString}
        </span>
      )}

      {!!comparePriceDiscountPercent && showDiscountPercent && (
        <span
          className={cx({
            'text-sm': !variant,
            'text-xs': variant === 'sm',
            'text-base': variant === 'lg',
          })}
        >
          {strings.productDiscountText.replace(
            /{percent}/gi,
            comparePriceDiscountPercent.toString()
          )}
        </span>
      )}
    </div>
  )
}

export default ProductPrice
