import cx from 'classnames'
import { useContext } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSummaryShippingProps {
  title?: string
  price: number
  className?: string
}

const CartSummaryShipping = ({
  title,
  price,
  className,
}: CartSummaryShippingProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const amountWithCurrency = getPriceWithCurrency(price, currency, {
    taxRate,
    thousandSeparator: ',',
  })

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {strings.cartShipping}
        <span className="text-gray-700">{title}</span>
      </div>
      <div className="shrink-0">{amountWithCurrency}</div>
    </div>
  )
}

export default CartSummaryShipping
