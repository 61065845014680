import cx from 'classnames'
import { motion } from 'framer-motion'
import { type ReactNode, useState } from 'react'

import { accordionContentAnimation } from '@lib/animate'

import Icon from '@components/icon'

interface AccordionProps {
  id: string
  title?: ReactNode
  defaultIsOpen?: boolean
  borderBottom?: boolean
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const Accordion = ({
  id,
  title,
  defaultIsOpen = false,
  borderBottom,
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  return (
    <div
      className={cx(
        'border-divider',
        {
          'border-b': borderBottom,
          'border-t': !borderBottom,
        },
        className
      )}
    >
      <button
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        aria-expanded={isOpen}
        aria-controls={`accordion-${id}`}
        className="text-left flex justify-between items-center py-5 w-full"
      >
        <h4 className="text-sm font-medium">{title}</h4>
        <Icon
          id={`accordion-icon-${id}`}
          name="ChevronDown"
          className={cx(
            'transition-transform duration-300 ease-custom-1 text-current h-full text-2xl',
            {
              'transform rotate-180': isOpen,
            }
          )}
        />
      </button>

      <motion.div
        id={`accordion-${id}`}
        initial={defaultIsOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={accordionContentAnimation}
        transition={{
          duration: 0.5,
          ease: [0.19, 1.0, 0.22, 1.0],
        }}
        className="overflow-hidden"
      >
        <div className={cx('m-0 pb-5', innerClassName)}>{children}</div>
      </motion.div>
    </div>
  )
}

export default Accordion
