import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type Locale } from '@lib/language'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { getLinkPageUrl } from '@lib/routes'
import { type LocalizedShop, ShopContext } from '@lib/shop-context'
import { getShopifyGlobalId } from '@lib/shopify/client'
import { UserContext } from '@lib/user/context'
import { emptyCart, getOrCreateCart, setCartBuyerIdentity } from './helpers'
import { type Cart } from './types'

interface CartContextProps {
  cart: Cart
  isCartOpen: boolean
  isCartUpdating: boolean
  isCreatingProduct: boolean
  cartAddingProductIds: number[]
  isCartSubmitting: boolean
  openCartInModal?: boolean
  toggleCart: (newState: boolean) => void
  saveCart: (locale: Locale, cart?: Cart) => void
  setIsCartUpdating: (isCartUpdating: boolean) => void
  setIsCreatingProduct: (isCreatingProduct: boolean) => void
  setCartAddingProductIds: (cartAddingProductIds: number[]) => void
  setIsCartSubmitting: (isCartSubmitting: boolean) => void
}

interface CartContextProviderProps {
  site: SanitySiteFragment
  localizedShop: LocalizedShop
  locale: Locale
  children: ReactNode
}

export const CartContext = createContext<CartContextProps>({
  cart: emptyCart,
  isCartOpen: false,
  isCartUpdating: false,
  isCreatingProduct: false,
  cartAddingProductIds: [],
  isCartSubmitting: false,
  toggleCart: () => null,
  saveCart: () => null,
  setIsCartUpdating: () => null,
  setIsCreatingProduct: () => null,
  setCartAddingProductIds: () => null,
  setIsCartSubmitting: async () => null,
})

/**
 * The cart context provider.
 */
export const CartContextProvider = ({
  site,
  localizedShop,
  locale,
  children,
}: CartContextProviderProps) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { user } = useContext(UserContext)

  const [cart, setCart] = useState<Cart>(emptyCart)
  const [openCartInModal, setOpenCartInModal] = useState(
    !site.cartSettings?.openInSeparatePage
  )
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isCartUpdating, setIsCartUpdating] = useState(false)
  const [isCreatingProduct, setIsCreatingProduct] = useState(false)
  const [cartAddingProductIds, setCartAddingProductIds] = useState<number[]>([])
  const [isCartSubmitting, setIsCartSubmitting] = useState(false)

  const router = useRouter()

  const saveCart = useCallback((locale: Locale, cart?: Cart) => {
    if (!cart) {
      return
    }

    setCart(cart)

    if (typeof window !== 'undefined') {
      const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
      localStorage.setItem(shopifyCartIdStorageKey, cart.id)
    }
  }, [])

  const toggleCart = useCallback(
    (newState: boolean) => {
      if (!openCartInModal) {
        if (newState) {
          const url = getLinkPageUrl('cartPage')
          router.push(url, url, {
            locale,
          })
        }

        return
      }

      if (isCartOpen !== newState) {
        setIsCartOpen(newState)
      }
    },
    [isCartOpen, openCartInModal, locale, router]
  )

  // Load cart settings (update when switching language)
  useEffect(() => {
    setOpenCartInModal(!site.cartSettings?.openInSeparatePage)
  }, [site.cartSettings])

  // Load cart from Shopify
  useEffect(() => {
    if (
      !shopifyStorefrontClient ||
      !localizedShop.shop ||
      localizedShop.locale !== locale
    ) {
      return
    }

    const loadCart = async () => {
      const newCart = await getOrCreateCart(
        locale,
        shopifyStorefrontClient,
        localizedShop.cart
      )
      saveCart(locale, newCart)
    }
    loadCart()
  }, [locale, saveCart, localizedShop, shopifyStorefrontClient])

  // Update cart buyer identity based on logged in user
  useEffect(() => {
    const customer = user?.customer
    const customerAccessToken = user?.token

    if (
      !shopifyStorefrontClient ||
      !cart.id ||
      !customer ||
      !customerAccessToken
    ) {
      return
    }

    // Check if cart customer ID is correct
    const shopifyCustomerId = getShopifyGlobalId(
      'Customer',
      customer.customerId
    )

    if (cart.customerId === shopifyCustomerId) {
      return
    }

    const updateCart = async () => {
      const newCart = await setCartBuyerIdentity(
        locale,
        shopifyStorefrontClient,
        cart.id,
        customerAccessToken
      )
      saveCart(locale, newCart)
    }
    updateCart()
  }, [cart, locale, saveCart, shopifyStorefrontClient, user])

  return (
    <CartContext.Provider
      value={{
        cart,
        isCartOpen,
        isCartUpdating,
        isCreatingProduct,
        cartAddingProductIds,
        isCartSubmitting,
        openCartInModal,
        toggleCart,
        saveCart,
        setIsCartUpdating,
        setIsCreatingProduct,
        setCartAddingProductIds,
        setIsCartSubmitting,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
