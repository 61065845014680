import cx from 'classnames'
import { useContext } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSummaryDiscountProps {
  amount: number
  title?: string
  quantity?: number
  className?: string
}

const CartSummaryDiscount = ({
  amount,
  title,
  quantity,
  className,
}: CartSummaryDiscountProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const totalAmount = amount * (quantity ?? 1)
  const amountWithCurrency = getPriceWithCurrency(totalAmount, currency, {
    taxRate,
    thousandSeparator: ',',
  })

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <div className="inline-flex flex-wrap gap-x-5 items-center">
        {!!strings.cartDiscount && <span>{strings.cartDiscount}</span>}
        <span className="text-gray-700">
          {title}
          {!!quantity && `(x${quantity})`}
        </span>
      </div>

      <span className="shrink-0">-{amountWithCurrency}</span>
    </div>
  )
}

export default CartSummaryDiscount
