import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { CartContext } from '@lib/cart/context'
import { getCartPrices } from '@lib/cart/helpers'
import { DiscountContext } from '@lib/discount/context'

import CartSummaryDiscount from './discount'
import CartSummaryShipping from './shipping'
import CartSummarySubtotal from './subtotal'
import CartSummaryTotal from './total'

interface CartSummaryProps {
  className?: string
}

const CartSummary = ({ className }: CartSummaryProps) => {
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  const cartPrices = useMemo(
    () => getCartPrices(cart, cartDiscountItems ?? []),
    [cart, cartDiscountItems]
  )

  return (
    <div className={cx(className)}>
      <CartSummarySubtotal price={cartPrices.subtotal} />

      {cartPrices.automaticDiscount > 0 && (
        <CartSummaryDiscount
          amount={cartPrices.automaticDiscount}
          quantity={1}
          title={cart.automaticDiscount?.title}
        />
      )}

      {!!cartDiscountItems && (
        <>
          {cartDiscountItems.map((cartDiscountItem) => (
            <CartSummaryDiscount
              key={cartDiscountItem.id}
              amount={cartDiscountItem.amount}
              quantity={cartDiscountItem.quantity}
              title={cartDiscountItem.title}
            />
          ))}
        </>
      )}

      {!!cart.hasShippingOptions && (
        <CartSummaryShipping
          title={cart.shippingTitle}
          price={cartPrices.shipping}
        />
      )}

      <CartSummaryTotal price={cartPrices.total} />
    </div>
  )
}

export default CartSummary
