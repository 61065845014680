import { type Locale } from './language'

type EnvironmentVariableKey =
  | 'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  | 'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'REPORT_URI_SUBDOMAIN'
  | 'FACEBOOK_CONVERSIONS_API_TOKEN'

type EnvironmentVariableValues = Record<
  EnvironmentVariableKey,
  string | undefined
>

const defaultLocaleEnvironmentVariables: EnvironmentVariableValues = {
  NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID,
  NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN,
  SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
  SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
  REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
  FACEBOOK_CONVERSIONS_API_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_TOKEN,
}

const localeEnvironmentVariables: Record<Locale, EnvironmentVariableValues> = {
  da: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_DA,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_DA,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_DA,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_DA,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_DA,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_DA,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: EnvironmentVariableKey
) => {
  const localeValue = localeEnvironmentVariables[locale]?.[key]

  if (typeof localeValue === 'undefined' || localeValue === '') {
    return defaultLocaleEnvironmentVariables[key]
  }

  return localeValue
}
