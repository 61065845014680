import cx from 'classnames'
import { useContext } from 'react'

import { getPriceWithCurrency } from '@lib/format'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSummaryTotalProps {
  price: number
  className?: string
}

const CartSummaryTotal = ({ price, className }: CartSummaryTotalProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const amountWithCurrency = getPriceWithCurrency(price, currency, {
    taxRate,
    thousandSeparator: ',',
  })

  return (
    <div
      className={cx(
        'text-sm flex gap-x-5 justify-between font-medium border-b py-3.5',
        className
      )}
    >
      <span>{strings.cartTotal}</span>
      <span className="shrink-0">{amountWithCurrency}</span>
    </div>
  )
}

export default CartSummaryTotal
