import cx from 'classnames'
import { type FormEvent, type ReactNode } from 'react'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

interface AlertProps {
  children: ReactNode
  onClick?: (e: FormEvent) => void
  buttonText?: string
  buttonVariant?: ButtonVariant
  buttonColor?: ButtonColor
  className?: string
  error?: boolean
  success?: boolean
}

const Alert = ({
  children,
  onClick,
  buttonText,
  buttonVariant = ButtonVariant.OUTLINED,
  buttonColor = ButtonColor.DEFAULT,
  className,
  error,
  success,
}: AlertProps) => {
  return (
    <div
      className={cx(
        'py-4 px-5 text-center font-medium rounded-md',
        {
          'bg-transparent border border-current/15 text-current':
            !error && !success,
          'text-success bg-success/5 border-success': success,
          'text-error bg-error/5 border-error': error,
        },
        className
      )}
    >
      {children}
      {onClick && buttonText && (
        <div className="mt-4">
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={ButtonSize.SMALL}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Alert
