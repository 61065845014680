import axios, { type AxiosResponse } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

import { LanguageContext } from '@lib/language-context'
import { parseResultsUnknownError } from '@lib/request'
import { ShopContext } from '@lib/shop-context'
import { getShopifyCustomerWithOrders } from '@lib/shopify/graphql/customers'
import { parseShopifyOrder } from '@lib/shopify/order'
import { UserContext } from './context'
import {
  type ApiAuthLoginResponse,
  type ApiAuthLogoutResponse,
  type LiveUser,
  type LoginFormValues,
  type LogoutPayload,
  type StoredUser,
  type UserKey,
  type UserOrder,
} from './types'

/**
 * Live user data and mutation hook.
 */
export const useUser = () => {
  const { locale } = useContext(LanguageContext)

  const userKey: UserKey = ['/api/auth/user', locale]
  const { data, mutate } = useSWR<StoredUser>(
    userKey,
    async ([url, locale]: UserKey) => {
      const response = await axios.get<StoredUser>(url, {
        headers: {
          'X-Locale': locale,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    }
  )

  const liveUser: LiveUser = {
    user: data,
    mutateUser: mutate,
  }
  return liveUser
}

/**
 * Returns a method that logs in a user.
 */
export const useLoginUser = () => {
  const { locale } = useContext(LanguageContext)
  const { mutateUser } = useContext(UserContext)

  return useCallback(
    async (payload: LoginFormValues) => {
      try {
        const apiLoginResponse = await axios.post<
          ApiAuthLoginResponse,
          AxiosResponse<ApiAuthLoginResponse>,
          LoginFormValues
        >('/api/auth/login', payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-Locale': locale,
          },
        })
        const { user, ...parseResults } = apiLoginResponse.data
        mutateUser(user)

        return parseResults
      } catch (error) {
        console.log(error)

        return parseResultsUnknownError
      }
    },
    [locale, mutateUser]
  )
}

/**
 * Returns a method that logs out a user.
 */
export const useLogoutUser = () => {
  const { locale } = useContext(LanguageContext)
  const { user, mutateUser } = useContext(UserContext)

  return useCallback(
    async (onSuccess?: () => void) => {
      const payload: LogoutPayload = {
        token: user?.token,
      }
      const apiLogoutResponse = await axios.post<
        ApiAuthLogoutResponse,
        AxiosResponse<ApiAuthLogoutResponse>,
        LogoutPayload
      >('/api/auth/logout', payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      // Update SWR cache and redirect to the login page
      mutateUser(apiLogoutResponse.data.user)

      if (onSuccess) {
        onSuccess()
      }
    },
    [locale, mutateUser, user?.token]
  )
}

/**
 * User order list hook.
 */
export const useUserOrderList = () => {
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { user } = useContext(UserContext)

  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const loadOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    try {
      const orderPageSize = 10
      const shopifyCustomer = await getShopifyCustomerWithOrders(
        shopifyStorefrontClient,
        user.token,
        orderCursor,
        orderPageSize
      )
      const newOrders =
        shopifyCustomer?.orders?.edges
          ?.map((edge) => edge.node)
          ?.filter(Boolean)
          ?.sort(
            (order1, order2) =>
              Date.parse(order2.processedAt) - Date.parse(order1.processedAt)
          )
          ?.map(parseShopifyOrder) ?? []

      setOrders((currentOrders) => [...currentOrders, ...newOrders])
      setOrderCursor(
        shopifyCustomer?.orders?.edges?.map((edge) => edge.cursor)?.pop() ??
          null
      )
      setHasMoreOrders(!!shopifyCustomer?.orders?.pageInfo?.hasNextPage)
    } catch (error) {
      console.log(error)

      setOrderCursor(null)
      setHasMoreOrders(false)
    }

    setIsLoadingOrders(false)
  }, [orderCursor, shopifyStorefrontClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      // Clear orders
      setOrders([])
      setOrderCursor(null)
      setHasMoreOrders(false)
      setIsInitialized(false)
    }
  }, [isInitialized, loadOrders, user?.token])

  return {
    orders,
    hasMoreOrders,
    loadOrders,
    isLoadingOrders,
  }
}
