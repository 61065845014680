import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  type HTMLAttributes,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  useContext,
} from 'react'

import { type SanityLink } from '@data/sanity/queries/types/link'
import { LanguageContext } from '@lib/language-context'
import { getFormattedQueryString, getLinkPageUrl } from '@lib/routes'
import { useLogoutUser } from '@lib/user/hooks'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'
import LinkProductCount from './link-product-count'

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
  showProductCategoryCount?: boolean
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  nextLinkClassName?: string
}

type ExternalLinkProps = Pick<SanityLink, 'url' | 'buttonStyle' | 'isButton'> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    id?: string
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const ExternalLink = ({
  id,
  url,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyle,
  isButton,
  className,
  children,
}: ExternalLinkProps) => {
  const buttonStyles = isButton ? buttonStyle : undefined

  return (
    <ButtonLink
      id={id}
      href={url}
      target="_blank"
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      rel="noopener noreferrer"
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        {
          btn: isButton,
          'w-full': buttonStyles?.isFullWidth,
        },
        className
      )}
    >
      {children}
    </ButtonLink>
  )
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  showProductCategoryCount,
  buttonVariant,
  buttonSize,
  buttonColor,
  'aria-label': ariaLabel,
  nextLinkClassName,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { locale } = useContext(LanguageContext)

  const router = useRouter()
  const logoutUser = useLogoutUser()

  const { _id: id, url, isButton, buttonStyle, page, queryString } = link
  const pageType = page?._type
  const pageSlug = page?.slug?.current

  // External link
  if (url) {
    return (
      <ExternalLink
        id={id}
        url={url}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyle={buttonStyle}
        isButton={isButton}
        className={className}
      >
        {children}
      </ExternalLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const basePageUrl = getLinkPageUrl(pageType, pageSlug)
  const formattedQueryString = getFormattedQueryString(queryString)
  const pageUrl = `${basePageUrl}${formattedQueryString}`

  const getClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (pageType === 'logoutPage') {
      event.preventDefault()

      logoutUser(() => {
        const url = getLinkPageUrl('loginPage')
        router.push(url, url, {
          locale,
        })
      })
    }

    if (onClick) {
      onClick()
    }
  }

  const beforeInputHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (pageType === 'logoutPage') {
      event.preventDefault()

      logoutUser(() => {
        const url = getLinkPageUrl('loginPage')
        router.push(url, url, {
          locale,
        })
      })
    }

    if (onClick) {
      onClick()
    }
  }

  const buttonStyles = isButton ? buttonStyle : undefined

  return (
    <NextLink
      href={pageUrl}
      className={cx(nextLinkClassName, {
        btn: isButton,
        'w-full': buttonStyles?.isFullWidth,
      })}
    >
      <Button
        id={id}
        role="link"
        tabIndex={tabIndex}
        onClick={getClickHandler}
        onBeforeInput={beforeInputHandler}
        aria-label={ariaLabel}
        variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
        size={buttonSize ?? getButtonSize(buttonStyles?.size)}
        color={buttonColor ?? getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx({ 'w-full': buttonStyles?.isFullWidth }, className)}
      >
        {children}
        <LinkProductCount
          page={page}
          showCollectionCount={showCollectionCount}
          showProductCategoryCount={showProductCategoryCount}
        />
      </Button>
    </NextLink>
  )
}

export default Link
