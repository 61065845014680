import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { muxVideoFragment, videoFragment } from './video'

const markDefFragment = groq`
  ...,
  _type == "link" => {
    page->{
      ${linkPageFragment}
    },
  },
`

export const simplePortableTextFragment = groq`
  ...,
  "markDefs": coalesce(markDefs[] {
    ${markDefFragment}
  }, []),
`

const addToCartButtonFragment = groq`
  productVariant->{
    variantID,
    inStock,
    price,
    "product": *[
      _type == "product" &&
      _id == ^.parentId
    ][0] {
      _id,
      "productCategoryId": category._ref,
      "collectionIds": *[
        _type == "collection" &&
        ^._id in products[]._ref
      ]._id,
    },
  },
`

const infoBoxFragment = groq`
  content[] {
    ${simplePortableTextFragment}
  },
`

const lottieAnimationFragment = groq`
  "assetUrl": file.asset->url,
`

const videoButtonFragment = groq`
  thumbnail {
    ${imageFragment}
  },
  video {
    ${videoFragment}
  },
`

export const complexPortableTextFragment = groq`
  ...,
  "markDefs": coalesce(markDefs[] {
    ${markDefFragment}
  }, []),
  _type == "addToCartButton" => {
    ${addToCartButtonFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == "infoBox" => {
    ${infoBoxFragment}
  },
  _type == 'lottieAnimation' => {
    ${lottieAnimationFragment}
  },
  _type == "videoEmbed" => {
    ${muxVideoFragment}
  },
  _type == "videoButton" => {
    ${videoButtonFragment}
  },
`
