import { useCallback, useContext, useMemo } from 'react'

import {
  type SanityProductVariantFragment,
  type SanityProductFragment,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'
import { usePrevious } from '@lib/hooks'
import { useUrlParameters } from '@lib/parameters'
import { parseOptionalParameter } from '@lib/request'
import { SiteContext } from '@lib/site-context'
import { getDefaultOption } from './option'

export const productVariantLowStockAmount = 5

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption)
  )

  return variant ?? null
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (product?: SanityProductFragment) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (!product) {
      return null
    }

    const defaultOption = getDefaultOption(
      product.options,
      product.optionSettings ?? []
    )
    const firstVariant = product.variants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [product])

  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'variant',
      value: defaultVariantId ? `${defaultVariantId}` : null,
    },
  ])

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return product?.variants?.map((variant) => variant.variantID) ?? []
  }, [product?.variants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      (activeParameter) => activeParameter.name === 'variant'
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return product?.variants?.find(
      (variant) => variant.variantID === activeVariantId
    )
  }, [product?.variants, activeVariantId])

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(
          (activeParameter) => activeParameter.name !== 'variant'
        ),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds]
  )

  return [activeVariant, updateProductPageUrl] as const
}

/**
 * Finds variant from active options and new option value.
 */
export const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  activeOptions: SanityProductVariantOption[],
  optionName: string,
  optionValue: string
) => {
  const newOptions = activeOptions.map((activeOption) =>
    activeOption.name === optionName
      ? {
          ...activeOption,
          value: optionValue,
        }
      : activeOption
  )

  // Find variant that matches all new options
  return variants.find((variant) =>
    variant.options.every((variantOption) =>
      hasObject(newOptions, variantOption)
    )
  )
}

/**
 * Gets product's default variant ID.
 */
export const getDefaultVariantId = (product: SanityProductFragment) => {
  if (!product) {
    return
  }

  const defaultOption = getDefaultOption(
    product.options,
    product.optionSettings ?? []
  )
  const firstVariant = product.variants?.[0]
  const defaultVariant = getVariantByDefaultOption(
    product.variants ?? [],
    defaultOption
  )
  const variant = defaultVariant ?? firstVariant

  return variant?.variantID
}

/**
 * Gets product's active variant.
 */
export const getActiveVariant = (
  product: SanityProductFragment,
  selectedVariantId?: number,
  defaultVariantId?: number
) => {
  const variantIds =
    product?.variants?.map((variant) => variant.variantID) ?? []
  const isSelectedVariantValid = variantIds.some(
    (id) => id == selectedVariantId
  )
  const activeVariantId = isSelectedVariantValid
    ? selectedVariantId
    : defaultVariantId

  return product?.variants?.find(
    (variant) => variant.variantID === activeVariantId
  )
}
