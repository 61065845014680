import { createContext, useContext, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { LanguageContext } from '@lib/language-context'
import { useProductWithInventory } from './inventory'
import { useActiveVariant } from './variant'
import {
  type ProductContextProps,
  type ProductContextProviderProps,
} from './types'

export const ProductContext = createContext<ProductContextProps>({
  setProduct: () => null,
  onVariantChange: () => null,
})

export const ProductContextProvider = ({
  children,
}: ProductContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityProductFragment>()

  // Get active product and variant
  const productWithInventory = useProductWithInventory(locale, product)
  const [activeVariant, onVariantChange] =
    useActiveVariant(productWithInventory)

  return (
    <ProductContext.Provider
      value={{
        product: productWithInventory,
        setProduct,
        activeVariant,
        onVariantChange,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
