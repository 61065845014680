import { gql } from '@apollo/client'

import {
  cartFragment,
  cartUserErrorFragment,
  cartWarningFragment,
} from '../objects/cart'

export const createCartMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const updateCartBuyerIdentityMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}
  ${cartWarningFragment}

  mutation updateCartBuyerIdentity(
    $buyerIdentity: CartBuyerIdentityInput!
    $cartId: ID!
  ) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
      warnings {
        ...CartWarningFragment
      }
    }
  }
`

export const updateCartAttributesMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation updateCartAttributes($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const updateCartNoteMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation updateCartNote($cartId: ID!, $note: String!) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const addCartLinesMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation addCartLines($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const updateCartLinesMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation updateCartLines($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const removeCartLinesMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`

export const updateCartDiscountCodesMutation = gql`
  ${cartFragment}
  ${cartUserErrorFragment}

  mutation updateCartDiscountCodes($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
`
