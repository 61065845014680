import { useContext, useMemo } from 'react'

import { type SanityStrings } from '@data/sanity/queries/types/site'
import { type AttributeInput } from '@data/shopify/storefront/types'
import { type CartFormValues } from '@lib/cart/types'
import {
  type CombinedListingParameterMap,
  type CombinedListingProductResult,
  findCombinedListingProduct,
  ProductCombinedListingContext,
} from '@lib/product/combined-listing'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

// Namespaces
export const shopifyCustomNamespace = 'custom'
export const shopifyGlobalNamespace = 'global'
export const shopifySanityNamespace = 'sanity'

// Metafield keys
export const shopifyCombinedListingDiscountMetafield =
  'combined_listing_discount'
export const shopifyCombinedListingProductSkuMetafield =
  'combined_listing_product_sku'
export const shopifyCustomerSyncMetafield = 'customer_sync'
export const shopifyDescriptionTagMetafield = 'description_tag'
export const shopifyProductIdMetafield = 'product_id'
export const shopifyProductSyncMetafield = 'product_sync'
export const shopifyTitleTagMetafield = 'title_tag'
export const shopifyVatNumberMetafield = 'vat_number'

// Attribute names
export const shopifyCombinedListingIdAttribute = '_combined_listing_id'
export const shopifyCombinedListingPassepartoutHoleSizeAttribute =
  '_combined_listing_passepartout_hole_size'
export const shopifyCombinedListingProductIdsAttribute =
  '_combined_listing_product_ids'
export const shopifyCombinedListingProductUrlAttribute =
  '_combined_listing_product_url'
export const shopifyTaxExemptAttribute = '_tax_exempt'
export const shopifyTaxVatIdAttribute = '_vat_id'

// Attribute values
export const shopifyYesAttributeValue = 'yes'
export const shopifyNoAttributeValue = 'no'

/**
 * Gets tax-related cart attributes.
 */
export const getTaxCartAttributes = (
  cartFormValues: CartFormValues,
  taxExempt: boolean
) => {
  const attributes: AttributeInput[] = []

  if (cartFormValues.vatId) {
    // Set VAT ID checkout attribute
    attributes.push({
      key: shopifyTaxVatIdAttribute,
      value: cartFormValues.vatId,
    })

    // Set tax exemption checkout attribute
    attributes.push({
      key: shopifyTaxExemptAttribute,
      value: taxExempt ? shopifyYesAttributeValue : shopifyNoAttributeValue,
    })
  }

  return attributes
}

/**
 * Gets product option from combined listing product result.
 */
const getCombinedListingProductCartAttribute = (
  strings: SanityStrings,
  result: CombinedListingProductResult
): AttributeInput | undefined => {
  switch (result.type) {
    case 'frame': {
      return {
        key: strings.combinedListingFrame,
        value:
          result.product.combinedListingOptionTitle ?? result.product.title,
      }
    }

    case 'backboard': {
      return {
        key: strings.combinedListingBackboard,
        value:
          result.product.combinedListingOptionTitle ?? result.product.title,
      }
    }

    case 'glass': {
      return {
        key: strings.combinedListingGlass,
        value:
          result.product.combinedListingOptionTitle ?? result.product.title,
      }
    }

    case 'passepartout': {
      return {
        key: strings.combinedListingPassepartout,
        value:
          result.product.combinedListingOptionTitle ?? result.product.title,
      }
    }

    case 'spacer': {
      return {
        key: strings.combinedListingSpacer,
        value:
          result.product.combinedListingOptionTitle ?? result.product.title,
      }
    }
  }
}

/**
 * Gets hook that returns cart attributes for combined listing products.
 */
export const useCombinedListingCartAttributes = () => {
  const strings = useContext(StringsContext)
  const {
    combinedListingConfiguration,
    combinedListingActiveProducts,
    combinedListingActiveSize,
    combinedListingActivePassepartoutHoleSize,
  } = useContext(ProductCombinedListingContext)

  return useMemo(() => {
    const attributes: AttributeInput[] = []

    // When combined listing consists of only 1 variant, don't add attributes
    if (
      !combinedListingActiveProducts ||
      !combinedListingConfiguration ||
      !combinedListingActiveSize ||
      combinedListingActiveProducts.length === 1
    ) {
      return attributes
    }

    // Product attributes
    attributes.push({
      key: shopifyCombinedListingIdAttribute,
      value: combinedListingConfiguration._id,
    })

    const combinedListingActiveProductIds = combinedListingActiveProducts
      .map((product) => product.productID)
      .join(',')
    const frameProduct = combinedListingActiveProducts.find(
      (product) => product.combinedListingProductType === 'frame'
    )

    attributes.push({
      key: shopifyCombinedListingProductIdsAttribute,
      value: combinedListingActiveProductIds,
    })

    if (frameProduct) {
      const combinedListingUrlParameters: CombinedListingParameterMap = {
        products: combinedListingActiveProductIds,
        size: combinedListingActiveSize,
        passepartout: combinedListingActivePassepartoutHoleSize ?? null,
      }
      const combinedListingProductUrl = getLinkPageUrl(
        'product',
        frameProduct.slug.current,
        combinedListingUrlParameters
      )
      attributes.push({
        key: shopifyCombinedListingProductUrlAttribute,
        value: combinedListingProductUrl,
      })
    }

    combinedListingActiveProducts.forEach((product) => {
      const result = findCombinedListingProduct(
        combinedListingConfiguration,
        product.productID
      )
      const attribute = result
        ? getCombinedListingProductCartAttribute(strings, result)
        : null

      if (!result || !attribute) {
        return
      }

      attributes.push(attribute)

      // Passepartout hole size attribute
      if (
        result.type === 'passepartout' &&
        combinedListingActivePassepartoutHoleSize
      ) {
        attributes.push({
          key: shopifyCombinedListingPassepartoutHoleSizeAttribute,
          value: combinedListingActivePassepartoutHoleSize,
        })
        attributes.push({
          key: strings.combinedListingPassepartoutHoleSize,
          value: combinedListingActivePassepartoutHoleSize,
        })
      }
    })

    return attributes
  }, [
    combinedListingConfiguration,
    combinedListingActivePassepartoutHoleSize,
    combinedListingActiveProducts,
    combinedListingActiveSize,
    strings,
  ])
}
