import {
  type GetServerSidePropsContext,
  type GetStaticPropsContext,
} from 'next'
import { type ParsedUrlQuery } from 'querystring'

import { type Locale, defaultLocale } from './language'

export type UrlParameters = Record<string, string | null | undefined>

export type Slugs = string | string[] | null

export interface PageUrlOptions {
  newLocale?: Locale
  locale?: Locale
  parameters?: Record<string, string>
}

export type PageType =
  | 'accountAddressPage'
  | 'accountPage'
  | 'blogAuthor'
  | 'blogCategory'
  | 'blogPage'
  | 'blogPost'
  | 'cartPage'
  | 'collection'
  | 'errorPage'
  | 'homePage'
  | 'loginPage'
  | 'logoutPage'
  | 'orderSuccessPage'
  | 'page'
  | 'passwordRecoveryPage'
  | 'product'
  | 'productCategory'
  | 'shopPage'
  | 'signupPage'
  | 'signupSuccessPage'

export interface CustomStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale: Locale
  locales: Locale[]
}

export interface CustomErrorStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale?: Locale
  locales: Locale[]
}

export interface CustomServerSidePropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetServerSidePropsContext<Q> {
  locale: Locale
}

export interface StaticPathWithSlug {
  params: {
    slug: string
  }
  locale: Locale
}

export interface StaticPathWithSlugs {
  params: {
    slugs: string[]
  }
  locale: Locale
}

export const pageRoutes: Record<PageType, string[]> = {
  accountAddressPage: ['account', 'addresses'],
  accountPage: ['account'],
  blogAuthor: ['blog', 'author', '[author_slug]'],
  blogCategory: ['blog', 'category', '[category_slug]'],
  blogPage: ['blog'],
  blogPost: ['blog', '[post_slug]'],
  cartPage: ['cart'],
  collection: ['shop', '[collection_slug]'],
  errorPage: ['page-that-does-not-exist-EqchyqRX3HXNjTvx'],
  homePage: [],
  loginPage: ['login'],
  logoutPage: ['api', 'auth', 'logout'],
  orderSuccessPage: ['order-success'],
  page: ['[page_slug]'],
  passwordRecoveryPage: ['password-recovery'],
  product: ['products', '[product_slug]'],
  productCategory: ['shop', 'category', '[category_slug]'],
  shopPage: ['shop'],
  signupPage: ['signup'],
  signupSuccessPage: ['signup-success'],
}

/**
 * Determines if page type has product collection.
 */
export const isCollectionPageType = (pageType: PageType) => {
  const collectionPageTypes: PageType[] = ['collection', 'shopPage']

  return collectionPageTypes.includes(pageType)
}

/**
 * Determines if page type is product category.
 */
export const isProductCategoryPageType = (pageType: PageType) => {
  const productCategoryPageTypes: PageType[] = ['productCategory']

  return productCategoryPageTypes.includes(pageType)
}

const getUrlSegments = (
  pageType: PageType,
  slugs: string | string[] | null = null
) => {
  const urlSlugs = slugs && Array.isArray(slugs) ? slugs : [slugs]
  let slugIndex = 0

  return (
    pageRoutes[pageType]?.map((segment) => {
      // Replace segment placeholder with a slug or an empty string
      if (/\[.*\]/.test(segment)) {
        slugIndex += 1
        return urlSlugs?.[slugIndex - 1] ?? ''
      }

      return segment
    }) ?? []
  )
}

const getUrlParameterString = (parameters?: UrlParameters) => {
  const searchParams = new URLSearchParams()

  Object.entries(parameters ?? {}).forEach((parameter) => {
    if (parameter[0] && parameter[1]) {
      searchParams.set(parameter[0], parameter[1])
    }
  })

  return searchParams.toString()
}

/**
 * Gets relative page URL for Next.js links without locale segment.
 */
export const getLinkPageUrl = (
  pageType: PageType,
  slugs?: Slugs,
  parameters?: UrlParameters
) => {
  const pageUrlSegments = getUrlSegments(pageType, slugs)
  const url = pageUrlSegments.filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}

/**
 * Gets relative page URL.
 */
export const getPageUrl = (
  locale: Locale,
  pageType: PageType,
  slugs?: Slugs,
  parameters?: UrlParameters
) => {
  const localeSegment = locale !== defaultLocale ? `${locale}` : null
  const pageUrlSegments = getUrlSegments(pageType, slugs)
  const url = [localeSegment, ...pageUrlSegments].filter(Boolean).join('/')

  const urlParameters = getUrlParameterString(parameters)
  const urlWithParameters = [url, urlParameters].filter(Boolean).join('?')

  return `/${urlWithParameters}`
}

/**
 * Gets formatted query string.
 */
export const getFormattedQueryString = (queryString?: string) => {
  if (!queryString) {
    return ''
  }

  let formattedQueryString = `${queryString}`

  if (formattedQueryString.startsWith('?')) {
    formattedQueryString = formattedQueryString.slice(1)
  }

  return `?${formattedQueryString}`
}
