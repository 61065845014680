import cx from 'classnames'
import { useRouter } from 'next/router'
import {
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import { CartContext } from '@lib/cart/context'
import { usePrepareCart } from '@lib/cart/hooks'
import { createDraftOrder } from '@lib/cart/request'
import { type CartFormValues } from '@lib/cart/types'
import { DiscountContext } from '@lib/discount/context'
import { triggerInitiateCheckoutFacebookEvent } from '@lib/facebook-client'
import { LanguageContext } from '@lib/language-context'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { type ErrorMessages } from '@lib/request'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import SimplePortableText from '@components/simple-portable-text'

interface CartSubmitProps {
  cartSettings: SanityCartSettings
  cartFormValues: CartFormValues
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  onClick?: () => void
  children: ReactNode
  className?: string
}

const CartSubmit = ({
  cartSettings,
  cartFormValues,
  setErrorMessages,
  onClick,
  children,
  className,
}: CartSubmitProps) => {
  const { register, watch } = useForm()
  const { currencyCode, reloadShop } = useContext(ShopContext)
  const { cart, isCartUpdating, isCartSubmitting, setIsCartSubmitting } =
    useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const { settings } = useContext(SiteContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const router = useRouter()
  const prepareCart = usePrepareCart()

  const [isError, setIsError] = useState(false)

  const acceptTermsRegister = register('acceptTerms')
  const hasAgreed = watch('acceptTerms')

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      if (!user || !cart.hasShippingOptions) {
        return
      }

      setIsError(false)
      setIsCartSubmitting(true)

      const prepareCartResult = await prepareCart(cartFormValues)

      setErrorMessages((currentErrorMessages) => ({
        ...currentErrorMessages,
        vatId: prepareCartResult.errors.vatId,
      }))

      if (Object.entries(prepareCartResult.errors).length > 0) {
        console.log(prepareCartResult.errors)
        setIsCartSubmitting(false)
        return
      }

      try {
        await createDraftOrder(locale, cart, user)

        // Clear cart then reload shop and cart
        if (typeof window !== 'undefined') {
          const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
          localStorage.removeItem(shopifyCartIdStorageKey)
          reloadShop()
        }

        // Trigger on click handler
        if (onClick) {
          onClick()
        }

        setIsCartSubmitting(false)

        setTimeout(async () => {
          // Trigger Facebook event
          if (settings?.facebookEvents) {
            await triggerInitiateCheckoutFacebookEvent(
              locale,
              cart,
              cartDiscountItems ?? [],
              currencyCode
            )
          }

          // Redirect to order success page
          const url = getLinkPageUrl('orderSuccessPage')
          router.push(url, url, {
            locale,
          })
        }, 0)
      } catch (error) {
        console.log(error)

        setIsCartSubmitting(false)
        setIsError(true)
      }
    },
    [
      cart,
      cartDiscountItems,
      cartFormValues,
      currencyCode,
      locale,
      onClick,
      prepareCart,
      reloadShop,
      router,
      setErrorMessages,
      setIsCartSubmitting,
      settings,
      user,
    ]
  )

  const isDisabled =
    !user ||
    !cart.hasShippingOptions ||
    isCartUpdating ||
    isCartSubmitting ||
    (!!cartSettings.terms && !hasAgreed)

  return (
    <div className={cx('space-y-4', className)}>
      {!!cartSettings.terms && (
        <Checkbox formRegister={acceptTermsRegister}>
          <SimplePortableText content={cartSettings.terms} className="rc" />
        </Checkbox>
      )}

      <Button
        variant={ButtonVariant.FILLED}
        disabled={isDisabled}
        onClick={handleClick}
        className="w-full"
      >
        {isCartUpdating && <>{strings.buttonUpdating}</>}
        {!isCartUpdating && isCartSubmitting && <>{strings.buttonSubmitting}</>}
        {!isCartUpdating && !isCartSubmitting && <>{children}</>}
      </Button>

      {isError && !!cartSettings.createOrderError && (
        <Alert error>
          <SimplePortableText
            content={cartSettings.createOrderError}
            className="rc rc-alert"
          />
        </Alert>
      )}
      {!cart.hasShippingOptions && !!cartSettings.shippingOptionsMissing && (
        <Alert error>
          <SimplePortableText
            content={cartSettings.shippingOptionsMissing}
            className="rc rc-alert"
          />
        </Alert>
      )}
    </div>
  )
}

export default CartSubmit
