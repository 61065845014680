import { type DocumentDiscount } from '@lib/discount/types'
import { type ProductPriceOptions } from './types'

/**
 * Applies discount percent to price.
 */
export const applyDiscountPercent = (
  price?: number,
  discountPercent?: number
) => {
  if (typeof price === 'undefined' || typeof discountPercent === 'undefined') {
    return price
  }

  const discountRate = discountPercent / 100
  return price * (1 - discountRate)
}

/**
 * Finds discount percent for a document in a list of document discounts.
 */
export const getDocumentPercentDiscount = (
  documentDiscounts: DocumentDiscount[],
  type: string,
  ids: string[]
) => {
  const normalizedIds = ids.filter(Boolean)
  const documentDiscount = documentDiscounts.find(
    (documentDiscount) =>
      documentDiscount.type === type &&
      normalizedIds.includes(documentDiscount.id)
  )

  return documentDiscount?.percentDiscount
}

/**
 * Gets product price and compare price.
 */
export const getProductPrices = (options: ProductPriceOptions) => {
  const percentDiscounts = [
    options.productPercentDiscount,
    options.collectionPercentDiscount,
    options.companyPercentDiscount,
  ]

  // Get variant or product price and compare price
  let price = options.variantPrice ?? options.productPrice
  let comparePrice = options.variantComparePrice ?? options.productComparePrice

  // Apply combined listing discount (applying to both price and compare price will hide the original full price)
  price = applyDiscountPercent(price, options.combinedListingPercentDiscount)
  comparePrice = applyDiscountPercent(
    comparePrice,
    options.combinedListingPercentDiscount
  )

  // Apply customer-related discounts (product discount, product category discount or company discount)
  percentDiscounts.forEach((percentDiscount) => {
    // Apply discount if compare price isn't already set (either from Shopify or from another discount)
    if (
      typeof comparePrice === 'undefined' &&
      typeof percentDiscount !== 'undefined'
    ) {
      comparePrice = applyDiscountPercent(price, percentDiscount)
    }
  })

  return [price, comparePrice] as const
}
