import axios from 'axios'
import { useMemo } from 'react'
import useSWR from 'swr'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { ProductVariantInventoryPolicy } from '@data/shopify/admin/types'
import { type Locale } from '@lib/language'
import { getShopifyGlobalId } from '@lib/shopify/client'
import { type ShopifyProductResult } from '@lib/shopify/graphql/products'
import { productLowStockAmount } from './product'
import { type ProductInventory, type ProductInventoryRequest } from './types'
import { productVariantLowStockAmount } from './variant'

/**
 * Parses Shopify product result and gets product inventory.
 */
export const getProductInventory = (
  product: ShopifyProductResult
): ProductInventory => {
  const isInfiniteStock = product.variants.some(
    (variant) =>
      !variant.inventoryTracked ||
      variant.inventoryPolicy === ProductVariantInventoryPolicy.Continue
  )
  const totalStock = product.variants.reduce(
    (total, variant) => total + (variant.inventoryQuantity ?? 0),
    0
  )

  return {
    inStock: isInfiniteStock || totalStock > 0,
    lowStock: !isInfiniteStock && totalStock <= productLowStockAmount,
    variants: product.variants.map((variant) => {
      const isInfiniteStock =
        !variant.inventoryTracked ||
        variant.inventoryPolicy === ProductVariantInventoryPolicy.Continue

      return {
        id: variant.id,
        inStock:
          isInfiniteStock ||
          (!!variant.inventoryQuantity && variant.inventoryQuantity > 0),
        lowStock:
          !isInfiniteStock &&
          !!variant.inventoryQuantity &&
          variant.inventoryQuantity <= productVariantLowStockAmount,
      }
    }),
  }
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  locale: Locale,
  product?: SanityProductFragment
): SanityProductFragment | undefined => {
  // Read product inventory
  const productInventoryRequest: ProductInventoryRequest = {
    localeHeader: locale,
    url: '/api/shopify/product-inventory',
    id: product?.productID,
  }
  const { data: productInventory } = useSWR<ProductInventory | undefined>(
    productInventoryRequest,
    async ({ localeHeader, url, id }: ProductInventoryRequest) => {
      if (!id) {
        return
      }

      const response = await axios.get<ProductInventory>(url, {
        params: {
          id,
        },
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    }
  )

  return useMemo(() => {
    if (!product || !productInventory) {
      return product
    }

    const productVariants = product.variants ?? []

    return {
      ...product,
      inStock: productInventory.inStock,
      lowStock: productInventory.lowStock,
      variants: productVariants.map((productVariant) => {
        const productInventoryVariant = productInventory.variants.find(
          (variant) =>
            variant.id ===
            getShopifyGlobalId('ProductVariant', productVariant.variantID)
        )

        if (!productInventoryVariant) {
          return productVariant
        }

        return {
          ...productVariant,
          ...productInventoryVariant,
        }
      }),
    }
  }, [product, productInventory])
}
