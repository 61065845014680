import {
  getShopAndCartQuery,
  getShopQuery,
} from '@data/shopify/storefront/queries/common'
import {
  type CartFragmentFragment,
  type GetShopAndCartQuery,
  type GetShopAndCartQueryVariables,
  type GetShopQuery,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { type ShopifyClient } from './client'

type ShopifyShopAndCart = [
  ShopFragmentFragment | undefined,
  CartFragmentFragment | undefined
]

/**
 * Gets Shopify shop information.
 */
const getShopifyShop = async (shopifyStorefrontClient: ShopifyClient) => {
  const getShopResult = await shopifyStorefrontClient.query<GetShopQuery>({
    query: getShopQuery,
  })

  const shop = getShopResult.data?.shop ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    undefined,
  ] as ShopifyShopAndCart
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId?: string
) => {
  if (!cartId) {
    return await getShopifyShop(shopifyStorefrontClient)
  }

  const getShopAndCartResult = await shopifyStorefrontClient.query<
    GetShopAndCartQuery,
    GetShopAndCartQueryVariables
  >({
    query: getShopAndCartQuery,
    variables: {
      cartId,
    },
  })

  const shop = getShopAndCartResult.data?.shop ?? undefined
  const cart = getShopAndCartResult.data?.cart ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    cart as CartFragmentFragment | undefined,
  ] as ShopifyShopAndCart
}
