import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type SanityCombinedListingConfiguration } from '@data/sanity/queries/types/product'
import { usePrevious } from '@lib/hooks'
import { type Parameter, setParameter, useUrlParameters } from '@lib/parameters'
import { SiteContext } from '@lib/site-context'
import {
  addCombinedListingProductToParameter,
  clearCombinedListingProductTypeFromParameter,
  combinedListingDefaultParameters,
} from './parameter'
import {
  findCombinedListingProducts,
  getCombinedListingActiveProductsIds,
  getCombinedListingActiveVariants,
} from './product'
import {
  type CombinedListingParameterKey,
  type CombinedListingParameter,
  type CombinedListingProductType,
  type CombinedListingStateManager,
} from './types'

/**
 * Returns combined listing state manager that uses URL parameters.
 */
export const useUrlStateManager =
  (): CombinedListingStateManager<CombinedListingParameter> => {
    const { isRouteChanging } = useContext(SiteContext)

    const [urlParameters, setUrlParameters] = useUrlParameters(
      combinedListingDefaultParameters
    )
    const previousUrlParameters = usePrevious(urlParameters)
    const activeUrlParameters = useMemo(
      () =>
        isRouteChanging && previousUrlParameters
          ? previousUrlParameters
          : urlParameters,
      [urlParameters, isRouteChanging, previousUrlParameters]
    )

    return {
      type: 'url',
      parameters: activeUrlParameters.map((urlParameter) => ({
        name: urlParameter.name as CombinedListingParameterKey,
        value:
          urlParameter.value && Array.isArray(urlParameter.value)
            ? urlParameter.value.join(',')
            : urlParameter.value,
      })),
      setParameters: setUrlParameters,
    }
  }

/**
 * Returns combined listing state manager that uses memory.
 */
export const useMemoryStateManager =
  (): CombinedListingStateManager<CombinedListingParameter> => {
    const [parameters, setParameters] = useState<CombinedListingParameter[]>(
      combinedListingDefaultParameters
    )

    return {
      type: 'memory',
      parameters,
      setParameters,
    }
  }

/**
 * Combined listing product hero functionality hook.
 */
export const useCombinedListing = (
  stateManager: CombinedListingStateManager<CombinedListingParameter>,
  combinedListingMainProductId?: number,
  combinedListingConfiguration?: SanityCombinedListingConfiguration
) => {
  const { parameters, setParameters } = stateManager

  const { isRouteChanging } = useContext(SiteContext)

  const parametersRef = useRef<Parameter[]>([])
  const parametersLoadedRef = useRef(true)
  const mainProductParameterLoadedRef = useRef(true)

  // Active products, variants, size and passepartout hole
  const combinedListingActiveProducts = useMemo(() => {
    const activeProductsIds = getCombinedListingActiveProductsIds(parameters)

    if (!activeProductsIds || !combinedListingConfiguration) {
      return []
    }

    return findCombinedListingProducts(
      combinedListingConfiguration,
      activeProductsIds
    )
  }, [parameters, combinedListingConfiguration])

  const combinedListingActiveSize = useMemo(() => {
    const sizeParameter = parameters.find(
      (parameter) => parameter.name === 'size'
    )

    return sizeParameter?.value
  }, [parameters])

  const combinedListingActiveVariants = useMemo(
    () =>
      getCombinedListingActiveVariants(
        combinedListingActiveProducts,
        combinedListingActiveSize ?? null
      ),
    [combinedListingActiveProducts, combinedListingActiveSize]
  )

  const combinedListingActivePassepartoutHoleSize = useMemo(() => {
    const passepartoutParameter = parameters.find(
      (parameter) => parameter.name === 'passepartout'
    )

    return passepartoutParameter?.value
  }, [parameters])

  /**
   * Adds a product ID to product parameter.
   */
  const addCombinedListingProduct = useCallback(
    (productId: number) => {
      if (!combinedListingConfiguration) {
        return
      }

      const newParameters = setParameter(
        parametersRef.current,
        'products',
        addCombinedListingProductToParameter(
          combinedListingConfiguration,
          parametersRef.current,
          productId
        )
      )
      parametersRef.current = newParameters
      setParameters(newParameters as CombinedListingParameter[])
    },
    [combinedListingConfiguration, setParameters]
  )

  /**
   * Sets size parameter value.
   */
  const setCombinedListingSize = useCallback(
    (size: string | null) => {
      const newParameters = setParameter(parametersRef.current, 'size', size)
      parametersRef.current = newParameters
      setParameters(newParameters as CombinedListingParameter[])
    },
    [setParameters]
  )

  /**
   * Sets passepartout hole parameter value.
   */
  const setCombinedListingPassepartoutHoleSize = useCallback(
    (passepartoutHoleSize: string | null) => {
      const newParameters = setParameter(
        parametersRef.current,
        'passepartout',
        passepartoutHoleSize
      )
      parametersRef.current = newParameters
      setParameters(newParameters as CombinedListingParameter[])
    },
    [setParameters]
  )

  /**
   * Removes product IDs from product parameter that match the specified type.
   */
  const clearCombinedListingProductType = useCallback(
    (type: CombinedListingProductType) => {
      if (!combinedListingConfiguration) {
        return
      }

      const newParameters = setParameter(
        parametersRef.current,
        'products',
        clearCombinedListingProductTypeFromParameter(
          combinedListingConfiguration,
          parametersRef.current,
          type
        )
      )
      parametersRef.current = newParameters
      setParameters(newParameters as CombinedListingParameter[])
    },
    [combinedListingConfiguration, setParameters]
  )

  /**
   * Clears product and size parameters.
   */
  const clearCombinedListing = useCallback(() => {
    const newParameters = [...combinedListingDefaultParameters]
    parametersRef.current = newParameters
    setParameters(newParameters)
  }, [setParameters])

  // Unset loaded state when product is changing
  useEffect(() => {
    if (combinedListingMainProductId && combinedListingConfiguration) {
      parametersLoadedRef.current = false
      mainProductParameterLoadedRef.current = false
    }
  }, [combinedListingMainProductId, combinedListingConfiguration])

  // Maintain an inner copy of URL parameters
  useEffect(() => {
    const productsParameter = parameters.find(
      (parameter) => parameter.name === 'products'
    )

    if (
      typeof productsParameter?.value === 'undefined' ||
      parametersLoadedRef.current
    ) {
      return
    }

    parametersRef.current = parameters
    parametersLoadedRef.current = true
  }, [parameters])

  // Add main product ID to product parameter
  useEffect(() => {
    // Wait for main product ID to be loaded and don't update after initial load
    if (
      isRouteChanging ||
      !combinedListingMainProductId ||
      mainProductParameterLoadedRef.current
    ) {
      return
    }

    const productsParameter = parameters.find(
      (parameter) => parameter.name === 'products'
    )

    if (typeof productsParameter?.value === 'undefined') {
      return
    }

    // Check if product ID isn't already in product parameter
    if (!productsParameter.value?.includes(`${combinedListingMainProductId}`)) {
      addCombinedListingProduct(combinedListingMainProductId)
    }

    mainProductParameterLoadedRef.current = true
  }, [
    addCombinedListingProduct,
    combinedListingMainProductId,
    isRouteChanging,
    parameters,
  ])

  return [
    combinedListingActiveProducts,
    combinedListingActiveVariants,
    combinedListingActiveSize,
    combinedListingActivePassepartoutHoleSize,
    addCombinedListingProduct,
    setCombinedListingSize,
    setCombinedListingPassepartoutHoleSize,
    clearCombinedListingProductType,
    clearCombinedListing,
  ] as const
}
